import toast from "react-hot-toast";

import { errorMessageExtractor } from "@/helpers/error-message";

import type { ErrorResponse } from "@/types/error";

export async function handleShare() {
  if (!navigator.share) {
    // Fallback for browsers that don't support Web Share API
    return;
  }

  try {
    await navigator.share({
      url: window.location.href,
    });
  } catch (e) {
    const { message } = errorMessageExtractor(e as ErrorResponse);

    toast.error(message, {
      duration: 3000,
      position: "bottom-right",
      style: {
        background: "#333",
        color: "#fff",
      },
    });
  }
}
